<template>
  <div @click="goToDetail(job.slug)" class="card card-style mt-1">
    <div class="content mt-3 mb-2">
      <h4 class="mb-2">{{ job.title }}</h4>
      <h5 class="font-13 font-600 opacity-70 line-height-s" style="color: #adb5bd">
        {{ job.work_loc_city.name }}
      </h5>
      <h5 class="font-13 font-600 opacity-70 line-height-s">
        <span v-if="job.get_level">{{ job.get_level }} - {{ job.get_worktime_display }}
        </span>
      </h5>
      <h5 class="font-13 opacity-70 line-height-s mb-2">
        <span v-if="!(job.salary_start === job.salary_end)">
          Rp {{ $filters.formatPrice(job.salary_start) }} - Rp {{
            $filters.formatPrice(job.salary_end)
          }}
        </span>
        <span v-if="job.salary_start === job.salary_end && !job.hide_salary">
          Sekitar Rp {{ $filters.formatPrice(job.salary_start) }}
        </span>
      </h5>
      <!-- Read More-->
      <div v-if="!job.company.hide_company" class="d-flex">
        <div class="me-auto">
          <div class="list-group list-custom-large mt-n3">
            <a href="#" class="border-0">
              <img :src="logoUrl" class="rounded-sm" @error="imageUrlAlt" />
              <span class="font-13 text-truncate" style="max-width: 60%">{{
                job.company.business_name
              }}</span>
              <strong v-if="job.company.city">{{
                job.company.city.name
              }}</strong>
            </a>
          </div>
        </div>
        <div>
          <a @click="goToDetail(job.slug)" class="
              btn btn-full btn-s
              font-600
              rounded-s
              mt-1
              float-start
            " style="background-color: rgb(255, 173, 58) !important">Lihat</a>
        </div>
      </div>
      <!-- End Read More-->
      <h5 v-if="!job.company.hide_company" class="font-13 font-500 mt-0">
        {{ timeLeft }}
      </h5>
      <div v-if="job.company.hide_company" class="d-flex mt-0">
        <h5 class="font-13 me-auto font-500 mt-2">{{ timeLeft }}</h5>
        <div class="">
          <a @click="goToDetail(job.slug)" class="
              btn btn-full btn-s
              font-600
              rounded-s
              float-start
              mt-0
              mb-2
            " style="background-color: rgb(255, 173, 58) !important">Lihat</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "ListJob",
  props: {
    job: Object,
    inviteCode: String,
  },
  inject: ["getApply"],
  data() {
    return {
      logoUrl: axios.defaults.baseURL + this.job.company.get_logo,
      timeLeft: this.getTime(this.job.get_time_left),
    };
  },
  mounted() {
  },
  methods: {
    goToDetail(id) {
      this.$router.push({
        name: "JobDetail",
        params: { id: id },
        query: { invite_code: this.inviteCode },
      });
    },
    imageUrlAlt(event) {
      event.target.src = "/images/defaults/default_logo.png";
    },
    getTime(time) {
      var re = /\d+(?=\s)/g;
      try {
        var timeLeft = time.match(re) ? time.match(re)[0] : "";
        let str = "";
        if (timeLeft) {
          str = "Berakhir " + timeLeft + " hari lagi";
        } else if (!timeLeft) {
          str = "Lowongan Berakhir Hari Ini";
        }
        return str;
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>
