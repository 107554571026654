<template>
  <div v-if="isLoading" id="preloader">
    <div class="spinner-border color-highlight" role="status"></div>
  </div>
  <div id="page">
    <!-- Header -->
    <Header :add_class="['header-auto-show']" />
    <!--End Header -->

    <!-- Footer -->
    <Footer v-if="isAuthenticated" logged />
    <Footer v-if="!isAuthenticated" default_footer />
    <!--End Footer -->

    <div class="page-title page-title-fixed">
      <h1>Filter</h1>
    </div>
    <div class="page-title-clear"></div>
    <div class="page-content">
      <!-- filter -->
      <div class="card card-style">
        <div class="content mb-0">
          <div class="row mb-3">
            <div class="col-6">
              <div
                class="
                  input-style
                  has-borders
                  no-icon
                  input-style-always-active
                  mb-4
                "
              >
                <label for="form512" class="color-highlight font-500"
                  >Brand</label
                >
                <select id="form512">
                  <option value="default" selected>Apple</option>
                  <option value="a">Huawei</option>
                  <option value="b">Samsung</option>
                  <option value="c">Nokia</option>
                  <option value="d">BlackBerry</option>
                </select>
                <span><i class="fa fa-chevron-down"></i></span>
                <i class="fa fa-check disabled valid color-green-dark"></i>
                <i class="fa fa-check disabled invalid color-red-dark"></i>
                <em></em>
              </div>
            </div>
            <div class="col-6">
              <div
                class="
                  input-style
                  has-borders
                  no-icon
                  input-style-always-active
                  mb-4
                "
              >
                <label for="form51" class="color-highlight font-500"
                  >Order By</label
                >
                <select id="form51">
                  <option value="default" selected>Ascending</option>
                  <option value="a">Descending</option>
                  <option value="b">High Price</option>
                  <option value="c">Low Price</option>
                  <option value="d">Best Rated</option>
                  <option value="e">Best Seller</option>
                </select>
                <span><i class="fa fa-chevron-down"></i></span>
                <i class="fa fa-check disabled valid color-green-dark"></i>
                <i class="fa fa-check disabled invalid color-red-dark"></i>
                <em></em>
              </div>
            </div>
          </div>

          <div class="divider"></div>
        </div>
      </div>
      <!-- filter -->

      <ListJob v-for="job in listJob" :key="job.id" :job="job" />
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { init_template, menuClose, menuOpen } from "@/components/menu/index.js";

import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import ListJob from "@/components/list_job/ListJob";

export default {
  name: "Filter",
  components: {
    Header,
    Footer,
    ListJob,
  },
  data() {
    return {
      listJob: [],
      apiUrl: "/api/v1/job-seeker/job-list/vacancy/",
      isAuthenticated: this.$store.state.isAuthenticated,
      isLoading: true,
    };
  },
  mounted() {
    init_template();
    this.getJobList();
    setTimeout(() => {
      this.isLoading = false;
    }, 150);
  },
  methods: {
    async getJobList() {
      await axios
        .get(this.apiUrl)
        .then((response) => {
          this.listJob = response.data.results;
          console.log("response data list : ", response.data);
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
